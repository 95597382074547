import React, { useState } from "react"
import { useMedia } from "use-media"

import { mixins } from "utils"

import "./Team.scss"
import { ReactComponent as Arrow } from "assets/icons/arrow.svg"
import { ReactComponent as Link } from "assets/icons/linked.svg"
import img1Src from "assets/images/team/1.webp"
import img3Src from "assets/images/team/3.webp"
import img4Src from "assets/images/team/4.webp"
import img5Src from "assets/images/team/5.webp"
import shape1Src from "assets/icons/team-left.svg"
import shape2Src from "assets/icons/team-right.svg"

export const Team = (): JSX.Element => {
  const [translateX, setTranslateX] = useState(0)
  const isM = useMedia({ maxWidth: mixins.m })

  const blocks = [
    {
      img: img1Src,
      name: "Sabir Magdeev",
      job: "CEO",
      link: "https://www.linkedin.com/in/sabir-magdeev-253429170/",
      desc: (
        <ul>
          <li>Graduated from Lomonosov Moscow State University (summa cum laude).</li>
          <li>
            In his 5+ years in the blockchain industry, Sabir has founded 5 startups in blockchain, IT and in between
            entertainment and blockchain industries.{" "}
          </li>
          <li>
            He is the head and founder of Venture studio, minor Partner of K&M Capital, CEO of the project with a $20M
            company valuation.
          </li>
        </ul>
      ),
    },
    {
      img: img5Src,
      name: "Alexander Goncharuk",
      job: "Co-founder, PM Beyond Desks",
      link: "https://www.linkedin.com/in/alexander-goncharuk/",
      desc: (
        <ul>
          <li>
            An active presence in the world of crypto since 2018, Alexander has founded 3 startups in crypto and IT.{" "}
          </li>
          <li>
            He acts as CPO in a project with a $20m company valuation and has experience in the largest Ed-Tech startup
            in the CIS.
          </li>
        </ul>
      ),
    },
    // {
    //   img: img3Src,
    //   name: "Anastasiia Muran",
    //   job: "COO",
    //   link: "https://www.linkedin.com/in/nastyamuran/",
    //   desc: (
    //     <ul>
    //       <li>Graduated from Higher School of Economics The National Research University.</li>
    //       <li>Founder and former CEO of Gmathub (ed-tech) and Loca-bi (localization project).</li>
    //       <li>Anastasiia was an adviser for several Web 3.0 projects.</li>
    //     </ul>
    //   ),
    // },
    {
      img: img4Src,
      name: "Vladimir Avetisyants",
      job: "PM LokrRoom",
      link: "https://www.linkedin.com/in/vladimir-avetisyants-82b15a21a/",
      desc: (
        <ul>
          <li>Graduated from Kutafin Moscow State Law University.</li>
          <li>
            His 4+ years of experience as an IT Project Manager include overseeing the development of software intended
            for selling banking and brokerage products online as well as managing international e-commerce intelligence
            projects.
          </li>
        </ul>
      ),
    },
  ]

  const onNextClick = () => {
    if (isM) {
      if (translateX === -780) {
        return setTranslateX(0)
      }

      return setTranslateX(translateX - 390)
    }

    if (translateX === -500) {
      return setTranslateX(0)
    }

    setTranslateX(translateX - 500)
  }

  const onPrevClick = () => {
    if (isM) {
      if (translateX === 0) {
        return setTranslateX(-780)
      }

      return setTranslateX(translateX + 390)
    }

    if (translateX === 0) {
      return setTranslateX(-500)
    }

    setTranslateX(translateX + 500)
  }

  return (
    <section id="team" className="team">
      <img alt="shape background" src={shape1Src} className="team-left" decoding="async" />
      <img alt="shape background" src={shape2Src} className="team-right" decoding="async" />
      <div className="team-title-wrapper">
        <button onClick={onPrevClick} className="team-title-wrapper-btn">
          <Arrow className="team-title-wrapper-arrow" />
        </button>
        <h3 className="team-title">
          Team<span>.</span>
        </h3>
        <button onClick={onNextClick} className="team-title-wrapper-btn">
          <Arrow />
        </button>
      </div>
      <div className="team-content-wrapper">
        <div className="team-content" style={{ transform: `translateX(${translateX}px)` }}>
          {blocks.map((item, index) => (
            <div className="team-block" key={index}>
              <div className="team-block-content">
                <div className="team-block-content-img-wrapper">
                  <img alt="logo" src={item.img} className="team-block-content-img" />
                </div>
                <div className="team-block-content-title">
                  <div className="team-block-content-title-name">
                    <div className="team-block-content-title-name-text">{item.name}</div>
                    <div className="team-block-content-title-name-job">{item.job}</div>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="team-block-content-title-link"
                    href={item.link}
                  >
                    <Link />
                  </a>
                </div>
                {item.desc}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

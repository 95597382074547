import React from "react"

import "./Footer.scss"

export const Footer = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="footer-reserved-wrapper">
        <p className="footer-reserved">©{new Date().getFullYear()} Pink Unicorn LTD. All Rights Reserved.</p>
        <a href="mailto:info@pinkunicornlabs.com" target="_blank" rel="noopener noreferrer" className="footer-email">
          info@pinkunicornlabs.com
        </a>
      </div>
      <div className="footer-links">
        <a className="footer-links-item" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a className="footer-links-item" target="_blank" rel="noopener noreferrer">
          Terms & Conditions
        </a>
      </div>
    </footer>
  )
}

import { useSearchParams } from "react-router-dom"
import { useEffect } from "react"

import { About, Contacts, Footer, Header, HeroBlock, Portfolio, Team } from "components"
import { scrollToElement, useScrollPosition } from "utils"

import "./Home.scss"

export const Home = (): JSX.Element => {
  const scroll = useScrollPosition()

  const [search] = useSearchParams()

  useEffect(() => {
    const id = search.get("id")
    if (id) {
      scrollToElement(id)
    }
  }, [search])

  return (
    <div className="home">
      <Header scroll={scroll} />
      <main>
        <HeroBlock />
        <About animation={scroll} />
        <Portfolio />
        <Team />
        <Contacts />
      </main>
      <Footer />
    </div>
  )
}

import React, { useState } from "react"
import cn from "classnames"

import "./Portfolio.scss"
import { ReactComponent as Arrow } from "assets/icons/arrow.svg"
import { ReactComponent as Beyond } from "assets/icons/beyond.svg"
import { ReactComponent as Fight } from "assets/icons/fight.svg"
import img1Src from "assets/images/portfolio/1.webp"
import img2Src from "assets/images/portfolio/2.webp"
import img3Src from "assets/images/portfolio/3.webp"
import imgLokroomSrc from "assets/images/lokrroom.webp"
import shapeSrc from "assets/icons/shape-portfolio.svg"

export const Portfolio = (): JSX.Element => {
  const [active, setActive] = useState<number | undefined>(undefined)

  const blocks = [
    {
      img: img1Src,
      title: "Beyond Desks",
      desc: "Educational metaverse for everyone. Immersive and decolonized learning experience that connects students, teachers, psychologists and parents in a gamified, fun and inclusive system.",
      link: "https://beyonddesks.com",
      logo: <Beyond />,
      className: "first",
    },
    {
      img: img2Src,
      title: "LokrRoom",
      desc: "One-stop professional networking platform that connects athletes with sponsors, agents, teams and leagues.",
      link: "https://lokrroom.com",
      logo: <img alt="lokrroom" src={imgLokroomSrc} />,
      className: "",
    },
    {
      img: img3Src,
      title: "Fight Zone",
      desc: "Partnered with Fight Zone Championship -  a unique martial arts promotion fueled by fan-driven matchmaking and the first to get NSAC’s sanction to move professional boxing to a caged hexagon.",
      link: "https://fightzonechampionship.com",
      logo: <Fight />,
      className: "last",
    },
  ]

  const carouselChildren = blocks.map((item, index) => (
    <section className={cn("portfolio-block", item.className)} key={index}>
      <div className="portfolio-block-content">
        <img alt="logo" src={item.img} className="portfolio-block-content-img" />
        <div className="portfolio-block-content-info">
          <div className="portfolio-block-content-info-title">
            <h4 className="portfolio-block-content-info-title-text">{item.title}</h4>
            {item.logo}
          </div>
          <p className="portfolio-block-content-info-desc">{item.desc}</p>
          <a target="_blank" rel="noopener noreferrer" className="portfolio-block-content-info-link" href={item.link}>
            {item.link}
          </a>
        </div>
      </div>
    </section>
  ))

  const onNextClick = () => {
    if (active !== undefined) {
      if (active === blocks.length - 1) {
        setActive(0)
      } else {
        setActive(active + 1)
      }
    } else {
      setActive(1)
    }
  }

  const onPrevClick = () => {
    if (active !== undefined) {
      if (active === 0) {
        setActive(blocks.length - 1)
      } else {
        setActive(active - 1)
      }
    } else {
      setActive(blocks.length - 1)
    }
  }

  return (
    <div id="portfolio" className="portfolio">
      <img alt="shape background" src={shapeSrc} className="portfolio-bg" decoding="async" />
      <div className="portfolio-title-wrapper">
        <button onClick={onPrevClick} type="button" className="portfolio-title-wrapper-btn">
          <Arrow className="portfolio-title-wrapper-arrow" />
        </button>
        <h3 className="portfolio-title">
          Portfolio<span>.</span>
        </h3>
        <button onClick={onNextClick} type="button" className="portfolio-title-wrapper-btn">
          <Arrow />
        </button>
      </div>
      <div className="portfolio-content-wrapper">
        <div className="portfolio-content">
          {carouselChildren.map((item, index) => (
            <div
              className={cn("carousel-item", {
                active: active === index,
                next:
                  active === undefined
                    ? index === 1
                    : active === blocks.length - 1
                    ? index === 0
                    : active + 1 === index,
                nextNext:
                  active === undefined
                    ? index === 2
                    : active === blocks.length - 2
                    ? index === 0
                    : active + 2 === index,
                prev:
                  active === undefined
                    ? index === blocks.length - 1
                    : active === 0
                    ? index === blocks.length - 1
                    : active - 1 === index,
                prevPrev:
                  active === undefined
                    ? index === blocks.length - 2
                    : active === 0
                    ? index === blocks.length - 2
                    : active - 2 === index,
                initial: index === 0 && active === undefined,
              })}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="portfolio-dots">
        {[0, 1, 2].map(dot => (
          <button
            key={dot}
            onClick={() => setActive(dot)}
            className={cn("portfolio-dots-item", {
              active: active === undefined ? dot === 0 : active === dot,
              small: active === 0 && dot === 2,
              smallOther: active === 2 && dot === 0,
            })}
          >
            <div className="portfolio-dots-item-content" />
          </button>
        ))}
      </div>
    </div>
  )
}

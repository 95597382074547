import React, { useEffect, useRef, useState } from "react"
import { useMedia } from "use-media"
import cn from "classnames"

import { mixins } from "utils"

import { ReactComponent as Pixels1 } from "assets/icons/home-left.svg"
import { ReactComponent as Pixels2 } from "assets/icons/home-right.svg"
import videoSrc from "assets/videos/bg.mp4"
import bgSrc from "assets/images/main-bg.webp"
import unicornSrc from "assets/gifs/unicorn.gif"
import unicornMobileSrc from "assets/gifs/unicorn-mobile.gif"
import "./HeroBlock.scss"

export const HeroBlock = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const [transition, setTransition] = useState("opacity 1s ease-in-out")
  const isM = useMedia({ maxWidth: mixins.m })
  const ref = useRef<any>(null)

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setOpacity(1)
      }, 10)

      setTimeout(() => {
        setOpacity(0)
        setTransition("opacity 0.5s ease-in-out")
      }, isM ? 1650 : 3200)

      const interval = setInterval(() => {
        setTimeout(() => {
          setOpacity(1)
        }, 10)

        setTimeout(() => {
          setOpacity(0)
          setTransition("opacity 0.5s ease-in-out")
        }, isM ? 1650 : 3200)
      }, 4700)
      return () => clearInterval(interval)
    }
  }, [loaded])

  return (
    <section
      id="main"
      className={cn("main", {
        notLoaded: !loaded,
      })}
      style={{ width: "auto" }}
    >
      <video onLoadedData={() => setLoaded(true)} className="main-video" muted autoPlay loop id="video">
        <source src={videoSrc} type="video/mp4" />
      </video>
      <div className="main-content-wrapper">
        <img style={{ opacity: opacity, transition: transition }} src={isM ? unicornMobileSrc : unicornSrc} alt="unicorn" className="main-gif" />
        <Pixels1 className="main-left" />
        <Pixels2 className="main-right" />
      </div>
      <img height={isM ? 1080 : ref?.current?.clientHeight} src={bgSrc} alt="bg" className="main-bg" />
      <h1 className="main-title">
        Pink Unicorn Ltd<span>.</span>
      </h1>
      <h2 className="main-subtitle">Venture Studio</h2>
      <h3 className="main-subtitle-2">Ideas turned into unicorns. Your and ours.</h3>
      <p className="main-desc">
        We believe that all startup ideas deserve their shot at becoming a unicorn – and we back it up by applying our
        diverse skills, startup experience and passion to our clients’ start up concepts, as well as our own.
      </p>
    </section>
  )
}

import React, { useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import cn from "classnames"
import { useMedia } from "use-media"

import { mixins, scrollToElement } from "utils"

import "./Header.scss"
import logoSrc from "assets/images/logo.webp"
import logo2Src from "assets/images/logo_white.webp"
import { ReactComponent as Menu } from "assets/icons/menu.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"

type Props = {
  scroll: number
}

export const Header = ({ scroll = 0 }: Props): JSX.Element => {
  const [sidebar, setSidebar] = useState(false)
  const [search] = useSearchParams()
  const navigate = useNavigate()
  const isM = useMedia({ maxWidth: mixins.m })

  const toggleSidebar = () => setSidebar(!sidebar)

  return (
    <header
      className={cn("header", {
        offset: scroll > 50 || isM,
      })}
    >
      <div
        className={cn("header-sidebar", {
          active: sidebar,
        })}
      >
        <button
          type="button"
          onClick={() => {
            navigate(`/?id=about`)
            scrollToElement("about")
            toggleSidebar()
          }}
          className={cn("header-sidebar-item", {
            active: search.get("id") === "about",
          })}
        >
          About
        </button>
        <button
          type="button"
          onClick={() => {
            navigate(`/?id=portfolio`)
            scrollToElement("portfolio")
            toggleSidebar()
          }}
          className={cn("header-sidebar-item", {
            active: search.get("id") === "portfolio",
          })}
        >
          Portfolio
        </button>
        <button
          type="button"
          onClick={() => {
            navigate(`/?id=team`)
            scrollToElement("team")
            toggleSidebar()
          }}
          className={cn("header-sidebar-item", {
            active: search.get("id") === "team",
          })}
        >
          Team
        </button>
        <button
          type="button"
          onClick={() => {
            navigate(`/?id=contacts`)
            scrollToElement("contacts")
            toggleSidebar()
          }}
          className={cn("header-sidebar-item", {
            active: search.get("id") === "contacts",
          })}
        >
          Contacts
        </button>
      </div>
      <div className="header-links first">
        {isM && (
          <button type="button" onClick={toggleSidebar} className={cn("header-links-item")}>
            {sidebar ? <Close /> : <Menu />}
          </button>
        )}
        {!isM && (
          <button
            type="button"
            onClick={() => {
              navigate(`/?id=about`)
              scrollToElement("about")
            }}
            className={cn("header-links-item", {
              active: search.get("id") === "about",
            })}
          >
            About
          </button>
        )}
        {!isM && (
          <button
            type="button"
            onClick={() => {
              navigate(`/?id=portfolio`)
              scrollToElement("portfolio")
            }}
            className={cn("header-links-item", {
              active: search.get("id") === "portfolio",
            })}
          >
            Portfolio
          </button>
        )}
      </div>
      <div className="header-logo-wrapper">
        <img className="header-logo" alt="logo" src={scroll > 50 || isM ? logoSrc : logo2Src} />
      </div>
      <div className="header-links">
        {!isM && (
          <button
            type="button"
            onClick={() => {
              navigate(`/?id=team`)
              scrollToElement("team")
            }}
            className={cn("header-links-item", {
              active: search.get("id") === "team",
            })}
          >
            Team
          </button>
        )}
        <button
          type="button"
          onClick={() => {
            navigate(`/?id=contacts`)
            scrollToElement("contacts")
          }}
          className={cn("header-links-item", {
            active: search.get("id") === "contacts",
          })}
        >
          Contacts
        </button>
      </div>
    </header>
  )
}

import { HttpClient } from "./http-client"

const apiUrl = "https://pink-unicorn-api.herokuapp.com"
if (!apiUrl) {
  throw new Error("API URL env must be set!")
}

interface ILead {
  email: string
  name: string
  message?: string
}

export class Api extends HttpClient {
  public constructor() {
    super(`${apiUrl}/api/v1`)
  }

  public createLead = (data: ILead) => {
    return this.instance.post<boolean>(`/leads`, data)
  }

  public fetch() {
    return this.instance.get("")
  }
}

const api = new Api()

export { api }

import React from "react"
import cn from "classnames"
import { useMedia } from "use-media"

import { mixins } from "utils"

import "./About.scss"
import { ReactComponent as Pixels } from "assets/icons/about-left.svg"
import { ReactComponent as Block } from "assets/icons/shape-about.svg"
import shapeSrc from "assets/icons/shape-about.svg"

type Props = {
  animation: number
}

export const About = ({ animation }: Props): JSX.Element => {
  const isM = useMedia({ maxWidth: mixins.m })

  const blocks = [
    {
      title: "Venture Studio",
      number: isM ? 300 : 700,
      className: "animate__animated animate__fadeIn animate__faster show",
      desc: "Pink Unicorn is a venture studio that is focused on launching startups not only in the worlds of metaverse, NFT, blockchain but also in classic video games, social entertainment and other traditional market IT. ",
    },
    {
      title: "Design & Development",
      number: isM ? 400 : 800,
      className: "animate__animated animate__fadeIn animate__faster animate__delay-05s show",
      desc: "Our ultimate goal is to create unicorns, drawing inspiration from our own designs and offering full development services for our clients’ concepts. Realizing the unique potential of your ideas will take a special effort. This is why we built a diverse team of professionals with the most experience and creativity possible.",
    },
    {
      title: "Web 3.0",
      number: isM ? 500 : 890,
      className: "animate__animated animate__fadeIn animate__faster animate__delay-1s show",
      desc: "Unicorns need somewhere to run free. Hence our second mission to expand and populate the world of Web 3.0: We develop Web 3.0 based products in areas like education, video games or eSports. That experience is put to good use: we offer consulting services and all-inclusive transfers to Web 3.0 for all kinds of sport-related businesses. Along the way, we develop tokenomics and NFTs.",
    },
    {
      title: "Sports Projects",
      number: isM ? 600 : 980,
      className: "animate__animated animate__fadeIn animate__faster animate__delay-15s show",
      desc: "Let’s be honest: we’ve all seen ambitious Web 3.0 startups end up forgotten before they could bloom. This is why we’re working on providing our products with maximum exposure. We already have our own eSports team to help our projects explode just like those Dota 2 magic spells, and even more exposure initiatives are on their way.",
    },
  ]
  return (
    <section id="about" className="about">
      <h3 className="about-title">
        About Us<span>.</span>
      </h3>
      {blocks.map((item, index) => (
        <dl className={cn("about-block", `${item.number < animation && item.className}`)} key={index}>
          <dt className="about-block-title">{item.title}</dt>
          <dd className="about-block-desc">{item.desc}</dd>
        </dl>
      ))}
      <Block className="about-bg" />
      {/*<img alt="shape background" src={shapeSrc} className="about-bg" decoding="async" />*/}
      <Pixels className="about-left" />
    </section>
  )
}

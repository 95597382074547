import React, { useState } from "react"

import { hrefs } from "utils"
import { api } from "service/api/api"

import "./Contacts.scss"
import { ReactComponent as Link } from "assets/icons/linked.svg"
// import { ReactComponent as Instagram } from "assets/icons/instagram.svg"
import { ReactComponent as Reddit } from "assets/icons/reddit.svg"
import { ReactComponent as Twitter } from "assets/icons/twitter.svg"
// import { ReactComponent as Discord } from "assets/icons/discord.svg"
// import { ReactComponent as Facebook } from "assets/icons/facebook.svg"
import { ReactComponent as Pixels } from "assets/icons/contacts-pixels.svg"
import mapSrc from "assets/icons/location.svg"
import messageSrc from "assets/icons/sms.svg"

export const Contacts = (): JSX.Element => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const links = [
    {
      href: hrefs.linkedIn,
      icon: <Link />,
    },
    // {
    //   href: hrefs.instagram,
    //   icon: <Instagram />,
    // },
    // {
    //   href: hrefs.discord,
    //   icon: <Discord />,
    // },
    {
      href: hrefs.twitter,
      icon: <Twitter />,
    },
    // {
    //   href: hrefs.facebook,
    //   icon: <Facebook />,
    // },
    {
      href: hrefs.reddit,
      icon: <Reddit />,
    },
  ]

  const onSubmit = event => {
    event.preventDefault()

    api
      .createLead({ name, email, message })
      .then(() => {
        alert("We will connect with you!")
        setMessage("")
        setName("")
        setEmail("")
      })
      .catch(e => alert(e.message))
  }

  return (
    <section id="contacts" className="contacts">
      <Pixels className="contacts-pixels" />
      <div className="contacts-content">
        <div className="contacts-content-info">
          <h3 className="contacts-title">
            Contacts<span>.</span>
          </h3>
          <div className="contacts-content-info-message">
            We would like to hear from you. <br /> Please send us a message
          </div>
          <address className="contacts-content-info-address">
            <div className="contacts-content-info-item">
              <img alt="map" src={mapSrc} decoding="async" />
              <div className="contacts-content-info-item-text">Cyprus based</div>
            </div>
            <div className="contacts-content-info-item">
              <img alt="message" src={messageSrc} decoding="async" />
              <a
                href="mailto:info@pinkunicornlabs.com"
                target="_blank"
                rel="noopener noreferrer"
                className="contacts-content-info-item-text"
              >
                info@pinkunicornlabs.com
              </a>
            </div>
          </address>
          <div className="contacts-content-info-links">
            {links.map((link, index) => (
              <a
                href={link.href}
                target="_blank"
                rel="noreferrer"
                className="contacts-content-info-links-item"
                key={index}
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
        <form onSubmit={onSubmit} className="contacts-content-form">
          <input onChange={e => setName(e.target.value)} name="name" required placeholder="Enter your Name" />
          <input
            onChange={e => setEmail(e.target.value)}
            name="email"
            required
            type="email"
            placeholder="Enter your E-mail*"
          />
          <textarea
            onChange={e => setMessage(e.target.value)}
            rows={4}
            name="message"
            required
            placeholder="Enter your Message..."
          />
          <button className="contacts-content-form-btn" type="submit">
            Send
          </button>
          <div className="contacts-content-form-text">* required to fill out</div>
        </form>
      </div>
    </section>
  )
}
